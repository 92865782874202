'use strict';

/**
 * Toggle script that is useful for showing navigation, modals, etc.
 *
 * The following data attributes are supported:
 *
 * data-toggle
 * data-toggle-class
 * data-toggle-body-class
 */

(function () {
    // Function to make it easier to read cookies
    function getCookie(name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }
    const minimised_menu_cookie = getCookie("minimised-menu");
    // const now = new Date();
    // now.setTime(now.getTime() + 1 * 3600 * 1000);

	const element = document.getElementById('minimise-menu');
    const bodyClass = 'is-collapsed';
    const toggleClass = 'is-toggled';
    const notCollapsedClass = 'not-collapsed';

	if (element.length === 0) {
		return false;
	}

    // Check cookie and set data attribute
    if (minimised_menu_cookie == '1') {
        element.classList.add(toggleClass);
        document.body.classList.add(bodyClass);
        document.body.classList.remove(notCollapsedClass);
    } else if (minimised_menu_cookie == '0' || minimised_menu_cookie == null) {
        element.classList.remove(toggleClass);
        document.body.classList.remove(bodyClass);
        document.body.classList.add(notCollapsedClass);
    }

    element.addEventListener('click', () => {
        if (document.body.classList.contains(bodyClass)) {
            element.classList.remove(toggleClass);
            document.body.classList.remove(bodyClass);
            document.body.classList.add(notCollapsedClass);
            document.cookie = "minimised-menu=0; max-age=2592000; path=/";
        } else {
            element.classList.add(toggleClass);
            document.body.classList.add(bodyClass);
            document.body.classList.remove(notCollapsedClass);
            document.cookie = "minimised-menu=1; max-age=2592000; path=/";
        }
    }, false);
}());
