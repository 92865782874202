'use strict';

(function () {
	const element = document.getElementById('copy-template');
	
	if (!element) {
		return;
	}

    const toggleClass = 'is-toggled';

    element.addEventListener('click', () => {
        element.classList.toggle(toggleClass);
        let copyText = document.getElementById("copy").innerHTML;
        let strippedString = copyText.replace(/(<([^>]+)>)/gi, "");

        navigator.clipboard.writeText(strippedString).then(function() {
            console.log('Copied!');
        }, function() {
            console.log('Copy error!')
        });
    }, false);
}());
