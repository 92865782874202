document.addEventListener("DOMContentLoaded", function() {

	var expandBtn = document.querySelectorAll('.sidebar-nav__btn');

	if (expandBtn) {
		expandBtn.forEach(function(item) {
			item.addEventListener('click', function() {
                this.classList.toggle('is-active');
                this.nextElementSibling.classList.toggle('is-expanded');
			});
		});
	}

});
