'use strict';

/**
 * Logic for close button in global alert
 *
 */

(function () {
	const element = document.getElementById('full-screen');
    const toggleClass = 'is-toggled';
    const bodyClass = 'is-fullscreen';

    if (!element) {
		return;
	}

    element.addEventListener('click', () => {
        if (document.body.classList.contains(bodyClass)) {
            element.classList.remove(toggleClass);
            document.body.classList.remove(bodyClass);
        } else {
            element.classList.add(toggleClass);
            document.body.classList.add(bodyClass);
        }
    }, false);
}());
