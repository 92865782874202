'use strict';

/**
 * Logic for close button in global alert
 *
 */

(function () {
    // Function to make it easier to read cookies
    function getCookie(name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    const banner_cookie = getCookie("banner");

    const element = document.getElementById('banner');
	const btn = document.getElementById('close-banner');
    const bannerClass = 'is-active';

	if (element == null) {
		return false;
	}

    // Check cookie and set data attribute
    if (banner_cookie == '0' || banner_cookie == null) {
        element.classList.add(bannerClass);
        console.log('test');
    }

    btn.addEventListener('click', () => {
        console.log('test');
        element.classList.remove(bannerClass);
        document.cookie = "banner=1; max-age=2592000; path=/";
    }, false);
}());
