window.addEventListener('DOMContentLoaded', () => {

	const pluginObserver = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			const id = entry.target.getAttribute('id');
			if (entry.intersectionRatio > 0) {
				var selector = document.querySelector(`.toc__list li a[href="#${id}"]`);
				if (selector) {
					selector.parentElement.classList.add('is-active');
				}
			} else {
				var selector = document.querySelector(`.toc__list li a[href="#${id}"]`);
				if (selector) {
					selector.parentElement.classList.remove('is-active');
				}
			}
		});
	});

	// Track all sections that have an `id` applied
	document.querySelectorAll('.has-anchor[id]').forEach((section) => {
		pluginObserver.observe(section);
	});

});
