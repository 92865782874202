'use strict';

(function () {
    var page_link_btn = document.querySelectorAll('.page-link-block');

    page_link_btn.forEach(element => {	
		element.addEventListener('click', function() {

            if(!this.classList.contains("is-expanded")) {
                this.classList.add('is-expanded');
            }
        });
	});
}());
