var mixer;
var container = document.querySelector('.post-list');
var topic = document.querySelector('#filter-topic');
// var type = document.querySelector('#filter-type');
var alert = document.getElementById("filter-alert");
var hash = window.location.hash;

if (container) {
    if (hash.replace('#', '') == '') {
        hash = 'all';
    } else {
        hash = '.' + hash.replace('#', '');
    }

    for (i = 0; i < topic.length; ++i){
        if (topic.options[i].value == hash){
            topic.value = hash;
        }
    }

    // for (i = 0; i < type.length; ++i){
    //     if (type.options[i].value == hash){
    //         type.value = hash;
    //     }
    // }

    var mixer = mixitup(container, {
        selectors: {
            target: '.page-link'
        },
        animation: {
            duration: 200,
            nudge: true,
            reverseOut: false,
        },
        load: {
            filter: hash
        },
        multifilter: {
            enable: true 
        },
        // pagination: {
        //     limit: 30
        // },
        callbacks: {
            onMixFail: function(state) {
                alert.style.opacity = "1";
            },
            onMixStart: function(state) {
                alert.style.opacity = "0"; 
            },
            // onPaginateEnd: function(state) {
            //     window.scrollTo(0,0);
            // }
        }
    });
}
