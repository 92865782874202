'use strict';

(function () {
	const element = document.getElementById('copy-link');
	
	if (!element) {
		return;
	}

    const toggleClass = 'is-toggled';

    element.addEventListener('click', () => {
        element.classList.toggle(toggleClass);
        let url = window.location.origin + window.location.pathname + '?share=true' + window.location.hash;

        navigator.clipboard.writeText(url).then(function() {
            console.log('Copied!');
        }, function() {
            console.log('Copy error')
        });
    }, false);
}());

//this function is for the copy anchor link. Cannot reuse the above as headings need an id to be anchor links
(function () {
	const anchors = document.querySelectorAll('.copy-anchor');

	if (anchors.length === 0) {
		return false;
	}

	anchors.forEach(anchor => {
	    anchor.addEventListener('click', () => {
	        var url = anchor.href;
	        console.log(url);

	        navigator.clipboard.writeText(url).then(function() {
	            console.log('Copied!');
	        }, function() {
	            console.log('Copy error')
	        });
    	}, false);
	});
}());



