import tippy from 'tippy.js';

tippy('.js-tippy-reviews', {
	theme: 'light',
	animation: 'shift-away-subtle',
    maxWidth: 240,
    arrow: true,
});

tippy('#copy-link', {
	theme: 'dark',
    arrow: true,
	animation: 'shift-away-subtle',
    trigger: 'click',
    onShow(instance) {
      setTimeout(() => {
        instance.hide();
      }, 2000);
    }
});


